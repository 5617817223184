import { OwnUpThemeWrapper } from '@rategravity/own-up-component-library-legacy';
import { InternalAuthProvider } from '@rategravity/widgets-react/modules/auth/internal/hooks/internal-auth-provider';
import { useInternalAuth } from '@rategravity/widgets-react/modules/auth/internal/hooks/use-internal-auth';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import querystring from 'querystring';
import React, { useEffect, useMemo } from 'react';
import { render } from 'react-dom';
import Loadable from 'react-loadable';
import './index.styl';
import { Route, Router } from 'react-router';
import { Redirect } from './components/router';
import { SplitProvider } from './split';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.DEPLOY_ENV,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: process.env.DEPLOY_ENV === 'prod' ? 0.01 : 1.0
  });
}

const history = createBrowserHistory();

const createApplicationSection = (doImport) => {
  return Loadable({
    loader: () => doImport(),
    loading: ({ error }) =>
      error ? (
        <div>
          <h1>ERROR ... </h1>
          <div> {error.message} </div>
          <hr />
          <div> {error.stack} </div>
        </div>
      ) : (
        <h1>Loading...</h1>
      )
  });
};

const Preapproval = createApplicationSection(() => import('./apps/preapproval'));
const Account = createApplicationSection(() => import('./apps/account'));
const Amortization = createApplicationSection(() => import('./apps/amortization'));
const AutoRefresh = createApplicationSection(() => import('./apps/rate-quote-refresher'));

const AppRoot = () => {
  const { isLoggedIn, login } = useInternalAuth();
  const loggingIn = useMemo(() => !isLoggedIn, [isLoggedIn]);
  // Force login on page load.
  useEffect(() => {
    if (!isLoggedIn) {
      void login(window.location.origin + window.location.pathname);
    }
  }, [isLoggedIn, login]);

  return loggingIn ? (
    <div>Logging you in...</div>
  ) : (
    <div>
      <Route path="/apps/preapproval" component={Preapproval} />
      <Route path="/apps/amortization" component={Amortization} />
      <Route path="/apps/auto-refresh" component={AutoRefresh} />

      {/* // Support old links referencing the obsolete questionnaire app. */}
      <Redirect
        from="/apps/loan-processing"
        to={({ location }) => {
          const { accountId } = querystring.parse(location.search.substr(1));
          return `/account/${accountId}`;
        }}
      />

      <Redirect
        from="/apps/account"
        to={({ location }) => {
          const { accountId } = querystring.parse(location.search.substr(1));
          return `/account/${accountId}`;
        }}
      />
      <Route path="/account" component={Account} />
      <Route path="/" exact={true} component={Account} />
    </div>
  );
};

render(
  <InternalAuthProvider apiBaseUrl={window.location.origin + '/api'}>
    <OwnUpThemeWrapper>
      <SplitProvider>
        <Router history={history}>
          <Route path="/" component={AppRoot} />
        </Router>
      </SplitProvider>
    </OwnUpThemeWrapper>
  </InternalAuthProvider>,
  document.getElementById('root')
);
